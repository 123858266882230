import CountUp from 'react-countup';

import './App.css';
import './Forge.css';

import React, { useRef, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import gug_logo from "./gug_logo_bordered.png";
import question_mark from "./question_mark.png";
import loading_image from "./loading.gif";
import steam_logo_large from "./steam_logo_large.png";
import error_image from "./error.png";
import steam_logo from "./steam_logo.png";
import discrod_logo from "./discord.png";
import link_copy from "./link_copy.png";
import facebook from "./facebook.png";
import whatsapp from "./whatsapp.png";
import twitter from "./twitter.png";
import reddit from "./reddit.png";

import wallpaper from "./wallpaper.png";
import grass from "./grass.png";

// import { GoogleLogin } from '@react-oauth/google';
// import { jwtDecode } from "jwt-decode";

import { useCookies } from 'react-cookie';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, RedditShareButton } from "react-share";

import ContentCreatorWidget from "./content_creator_widget";

const options = {
  autoConfig: true
}

const ENDPOINT_PATH = "https://gugappletbackend-production.up.railway.app/";
// const ENDPOINT_PATH = "http://127.0.0.1:5000/";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 30
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 16
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 6
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 6
  }
};

// From https://www.npmjs.com/package/react-multi-carousel
const Slideshow = (props) => {
  const ref= useRef();
  const [currentSlide, setCurrentSlide] = useState(20);
  const [reveal, setReveal] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const offset = props.isMobile ? (responsive['mobile']['items'] / 2) : responsive['desktop']['items'] / 2;

  useEffect(() => {
    var splitPoint = null;
    if (currentSlide == 40) {
      splitPoint = 20 + offset - 1;
    } else {
      splitPoint = currentSlide + offset - 1;
    }
    var ul = ref.current.children[0].children[0];
    for (var i in ul.children) {
      var el = ul.children[i];
      if (el.style == undefined) continue;
      if (el.dataset["index"] > splitPoint) {
        el.children[0].className = "customShadow";
      } else if (el.dataset["index"] > splitPoint - reveal) {
        el.children[0].className = "";
      } else if (el.dataset["index"] <= splitPoint) {
        el.children[0].className = "hide";
      }
    }
    setLoaded(true);
  });

  return (
    <div ref={ref}>
      <Carousel
        responsive={responsive}
        swipeable={false}
        draggable={false}
        autoPlay={true}
        infinite={true}
        centerMode={false}
        transitionDuration={500}
        autoPlaySpeed={2000}
        removeArrowOnDeviceType={["desktop", "mobile", "tablet"]}
        itemClass="carousel-item-padding-40-px"
        afterChange={(previousSlide, { currentSlide, onMove }) => {
          setCurrentSlide(currentSlide);
          setReveal(reveal + 1);
        }}
    >
        {props.data.map((item, index) => (
          <div>
            <div style={{display: loaded ? "block" : "none"}}>
              <img id={index} src={item} style={{width: "3.5em", zIndex: "-1", position: "relative"}}/>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
}

const EffectVisualiser = (props) => {
  const raw_effect = props.raw_effect;
  if (props.display_raw) return raw_effect;
  
  var parseEffects = (rawEffectData) => {
      const effects = [];
      // Pattern to match each effect part based on the given format.
      const pattern = /\[(?<event>[\w]+)(\([Tt]his\)|\([Aa]ny\))?\]\s*(→|->|--)\s*(?:\((?<name>[^)]+)\))?\[(?<content>[^\]]+)\]/g;
      const matches = rawEffectData.matchAll(pattern);
      for (const match of matches) {
        const effect = {
            EventName: match.groups.event,
            Name: match.groups.name || null,
            Content: match.groups.content
        };
        effects.push(effect);
      }

      return effects;
  }

  let effects = parseEffects(raw_effect);

  return (
    <div>
      {effects.map((effect) => (
      <div>
        <div style={{fontWeight: "bolder"}}>
          {effect.EventName.replace("Event", "").replace("Phase", "").replace("Effect", "")}
        </div>
        <div>
          {effect.Content.replace("creature", "gug")}
        </div>
      </div>
      ))}
    </div>
  );
}

const DynamicCounter = (props) => {
  const [count, setCount] = useState(0);
  const [prevCount, setPrevCount] = useState(0);

  useEffect(() => {
    fetch(ENDPOINT_PATH + "get_gugs_count_new")
    .then((res) => res.text())
    .then((value) => {
      setPrevCount(count);
      setCount(value);
    });

    const interval = setInterval(
      () => {
        fetch(ENDPOINT_PATH + "get_gugs_count_new")
        .then((res) => res.text())
        .then((value) => {
          setPrevCount(count);
          setCount(value);
      });
    }, 5000);

    return () => clearInterval(interval);
  }, [count, prevCount]);

  return (
    <div>
      <CountUp start={prevCount} end={count} /> Gugs Made
    </div>
  )
}

const GUGLibrary = (props) => {
  // const [cookies, setCookie, removeCookie] = useCookies(['login-credential']);
  const [cookies, setCookie, removeCookie] = useCookies(['collection'], {doNotUpdate: true});

  const [data, setData] = useState();
  const [collection, setCollection] = useState([]);
  const [new_gug, setNewGug] = useState({});
  const [prompt, setPrompt] = useState('');
  const [generating, setGenerating] = useState("never");
  const [shareURL, setShareURL] = useState("");
  const [loadingTip, setLoadingTip] = useState("");

  const [showCopy, setShowCopy] = useState(false);

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }

  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const isMobile = width <= 1024;

  const loadingTips = [
    "Join the GUG Discord for the latest updates",
    "Wishlist GUG on Steam",
    "Share your gug with your friends!",
    "GUG uses machine learning to modify its own code!",
    "There are infinite gugs, though we haven't counted",
    "GUG stands for Generatively Unsupervised GUG",
    "In GUG, you assemble gugs to counter the toxic wilds' threats",
    "Gugs can't tell left from right",
    "If you had a dollar for every time someone made a gug, you'd have at least 2 dollars",
    "There's a gug behind you, right now"
  ]
  
  useEffect(() => {
    fetch(ENDPOINT_PATH + "get_resized_gugs_subsample_new")
      .then((res) => res.json())
      .then((values) => {
        setData(values);
    });
  }, []);

  
  if (!cookies.collection) {
    setCookie("collection", []);
    return "";
  }

  if (cookies.collection.length != 0 && collection.length == 0) {
    setCollection(cookies.collection);
    setGenerating("never");
  }


  var handleClick = () => {
    if (generating == "yes") return;
    setGenerating("yes");
    setShareURL("");
    setLoadingTip(loadingTips[Math.floor(Math.random() * loadingTips.length)]);
    setNewGug({image_url: loading_image});
    fetch(ENDPOINT_PATH + 
      "generate_gug?keyword=" + prompt.replace(" ", "_") 
      // + "&credential=" + cookies.credential
    )
        .then((res) => res.json())
        .then((data) => {
          let path_to_poll = ENDPOINT_PATH + data['location'].substr(1);
          let apiTimeout = setTimeout(pollAPI, 1000);

          function pollAPI(){
            fetch(path_to_poll)
              .then((res) => res.json())
              .then((data) => {
                      if(data == ''){
                          // No data received, the result is still baking.
                          // Recreate a setTimeout API call which will be fired after 1 second.
                          apiTimeout = setTimeout(pollAPI, 1000);
                      } else {
                          // Result is ready.
                          if ('failure_reason' in data) {
                            setNewGug({image_url: error_image, effect: data['failure_reason'], name: data['failure'], display_raw: true});
                            setGenerating("no");
                            return;
                          }
                          if ('info_reason' in data) {
                            setNewGug({image_url: steam_logo_large, effect: data['info_reason'], name: data['info'], display_raw: true});
                            setGenerating("wait");
                            setTimeout(() => { setGenerating("no"); }, 5000);
                            return;
                          }
                          data['display_raw'] = false
                          data['image_url'] = data['image_url']
                          setNewGug(data);
                          setCookie("collection", collection.concat(data));
                          setCollection(collection.concat(data));
                          setGenerating("no");
                          setShareURL(`https://gug.gg/?keyword=${encodeURIComponent(data['prompt'])}`);

                          clearTimeout(apiTimeout);
                      }
              })
          }
        });
  };

  var onMouseOver = (item) => {
    if (generating == "yes") return;
    setNewGug(item);
  }

  // function responseMessage(response) {
  //   // const decoded = jwtDecode(response['credential']);
  //   // console.log(decoded['email']);
  //   setCookie('credential', response['credential']);
  // }
  
  if (!data) {
    return (
      <div style={{height: "100vh", margin: "auto", color: "#386256", fontWeight: "bold", fontSize: "2em", position: "relative"}}>
        <div style={{top: "50%", position: "relative", transform: "translateY(-50%)"}}>
          "Loading ..."
        </div>
      </div>
    );
  }

  if (Object.keys(new_gug).length == 0) setNewGug({image_url: question_mark});

  return (
    <div>
      <div style={{fontWeight: "bolder", color: "#386256"}}>
        <div style={{position: "absolute", width: "100%", backgroundColor: "#A4B88E", padding: "10px 0px 0px 0px", minHeight: "3.8em", borderBottom: "2px solid #386256", boxShadow: "0px 2px 5px black"}}>
          <Slideshow data={data} isMobile={isMobile} />
        </div>
        <img src={gug_logo} alt="GUG Logo" style={{height: "5em", zIndex: "1000", position: "relative"}}/>
        <div style={{paddingBottom: "15px"}}>
          <div style={{margin: "auto", display: "inline-block", fontSize: "0.7em", color: "#386256", fontWeight: "bold"}}>
            <DynamicCounter />
          </div>
          <div style={{display: "inline-block", paddingTop: "5px", marginLeft: "25px"}}>
            <a href={`https://store.steampowered.com/app/3256600/GUG_Demo/?utm_source=applet_a&utm_campaign=top_banner_a`} target='_blank'>
              <div style={{fontSize: "15px", color: "white", backgroundColor: "#171D25", width: "185px", margin: "auto", borderRadius: "20px", padding: "7px"}}>
                <div style={{height: "20px"}}>
                  <div style={{float: "left", height: "100%", top: "50%", position: "relative", transform: "translateY(-50%)", padding: "0px 5px 0px 10px"}}>
                    Play the FREE demo
                  </div>
                  <div style={{verticalAlign: "middle", display: "inline"}}>
                    <img src={steam_logo} width="20px" />
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div style={{border: "2px solid #386256", margin: "auto", backgroundColor: "#A4B88E", width: "15em", color: "#386256"}}>
        <h3> What is GUG? </h3>
        It's a game and is therefore better experienced than described. <br/>
        We recommend giving the above demo a try.
        <h3> When does it release? </h3> <a href="https://martianlawyersclub.notion.site/GUG-FAQ-170d2f4bb4f780bd8a66d08ca00f63c1" target="_blank">Check out our FAQ</a> for answers to questions like these.
        <h3> How do I meet other GUG-minded individals? </h3>
        <div style={{width: "100%", margin: "0 auto", paddingBottom: "30px"}}>
          <a href={`https://discord.com/invite/VkbF6SdBta`} target='_blank'>
            <div style={{fontSize: "20px", color: "white", backgroundColor: "#5765F2", width: "235px", borderRadius: "20px", padding: "10px", margin: "0 auto"}}>
              <div style={{height: "25px"}}>
                <div style={{float: "left", height: "100%", top: "50%", position: "relative", transform: "translateY(-50%)", padding: "0px 5px 0px 10px"}}>
                  Join the GUG Discord
                </div>
                <div style={{verticalAlign: "middle", display: "inline"}}>
                  <img src={discrod_logo} width="25px" />
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
      <img src={grass} style={{width: "calc(15em + 4px)"}}/>
      <ContentCreatorWidget isMobile={isMobile}/>
    </div>
  );
};


function Homev2() {
  
  return (
    <div className="App">
      <header className="App-header" style={{minHeight: "100vh", backgroundColor: `#F6E5D2`, display: "inherit", backgroundImage: `url(${wallpaper})`}}>
        <div style={{width:"100%", height: "100%", position: "relative"}}>
          <GUGLibrary />
        </div>
      </header>
    </div>
  );
}

export default Homev2;